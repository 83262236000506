import React from "react";
// import imag from '../assets/img/bg-aic_construccion.jpg';
import Contact from "./Contact";
// import Home from './Home';
import Portfolio from "./Portfolio";
import AboutUs from "./AboutUs";
import Services from "./Services";

// <Home />

function Content() {
  return (
    <div className="container">
      <Services />
      <AboutUs />
      <Portfolio />
      <Contact />
    </div>
  );
}

export default Content;
