import React from "react";
import imgVideo from "../assets/img/portafolio1.jpg";

function QuienesSomos() {
  return (
    <div id="aboutus" className="container about contenido">
      <div>
        <h2 className="text-center mb-5 text-dark">Quienes somos</h2>
      </div>

      <div className="row no-gutters">
        <div className="col-lg-6 d-flex flex-column justify-content-center about-content">
          <div>
            <p>
              Magnam dolores commodi suscipit. Necessitatibus eius consequatur
              ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam
              quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea.
            </p>
          </div>

          <div>
            <h4 className="title">
              <a href="">Lorem Ipsum</a>
            </h4>
            <p className="description">
              Voluptatum deleniti atque corrupti quos dolores et quas molestias
              excepturi sint occaecati cupiditate non provident
            </p>
          </div>

          <div>
            <h4 className="title">
              <a href="">Nemo Enim</a>
            </h4>
            <p className="description">
              At vero eos et accusamus et iusto odio dignissimos ducimus qui
              blanditiis praesentium voluptatum deleniti atque
            </p>
          </div>
        </div>
        <div className="card col-md-6 bg-secondary text-white">
          <img src={imgVideo} className="card-img" alt="..." />
          <div className="card-img-overlay d-flex justify-content-center align-content-center flex-wrap">
            <a
              href="https://www.youtube.com/watch?v=TI9nhf2bneg"
              class="venobox play-btn mb-4"
              data-vbtype="video"
              data-autoplay="true"
            >
              <i class="bi bi-play-circle-fill display-2 text-secondary"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuienesSomos;
