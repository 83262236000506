import React from "react";

const Footer = () => {
  return (
    <div id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div class="col-lg-3 col-md-6 mt-4 footer-links">
              <h4>Nuestros servicios</h4>
              <ul>
                <li>
                  <i class="bx bx-chevron-right"></i>{" "}
                  <a href="#" className="text-white">
                    Web Design
                  </a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>{" "}
                  <a href="#" className="text-white">
                    Web Development
                  </a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>{" "}
                  <a href="#" className="text-white">
                    Product Management
                  </a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>{" "}
                  <a href="#" className="text-white">
                    Marketing
                  </a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>{" "}
                  <a href="#" className="text-white">
                    Graphic Design
                  </a>
                </li>
              </ul>
            </div>

            <div class="col-lg-4 col-md-6 mt-4 footer-newsletter">
              <h4>Our Newsletter</h4>
              <p>
                Tamen quem nulla quae legam multos aute sint culpa legam noster
                magna
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
