import React from "react";

const Head = () => {
  return (
    <div className="row py-2 border-bottom align-items-center">
      <div id="topbar" className="d-none d-lg-block">
        <div className="container clearfix px-2">
          <div className="contact-info float-start">
            <i class="bi bi-envelope-fill text-primary me-2"></i>
            <a href="mailto:contact@example.com">contact@example.com</a>
            <i className="bi bi-telephone-fill text-primary ms-3"></i> +1 5589
            55488 55
          </div>
          <div className="social-links float-end">
            <a href="#" className="twitter">
              <i class="bi bi-twitter text-primary px-2"></i>
            </a>
            <a href="#" className="facebook">
              <i class="bi bi-facebook text-primary px-2 text-primary px-2"></i>
            </a>
            <a href="#" className="instagram">
              <i class="bi bi-instagram text-primary px-2"></i>
            </a>
            <a href="#" className="skype">
              <i class="bi bi-skype text-primary px-2"></i>
            </a>
            <a href="#" className="linkedin">
              <i class="bi bi-linkedin text-primary px-2"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Head;
