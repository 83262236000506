import React from "react";

function Servicios() {
  return (
    <div id="services" className="container services contenido">
      <div>
        <h2 className="text-center mb-5 text-dark">Servicios</h2>
      </div>

      <div className="row">
        <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
          <div className="icon">
            <i className="bi bi-display"></i>
          </div>
          <h4 className="title">
            <a href="">Lorem Ipsum</a>
          </h4>
          <p className="description">
            Voluptatum deleniti atque corrupti quos dolores et quas molestias
            excepturi sint occaecati cupiditate non provident
          </p>
        </div>
        <div
          className="col-lg-4 col-md-6 icon-box"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="icon">
            <i className="bi bi-bar-chart-line-fill"></i>
          </div>
          <h4 className="title">
            <a href="">Dolor Sitema</a>
          </h4>
          <p className="description">
            Minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat tarad limino ata
          </p>
        </div>
        <div
          className="col-lg-4 col-md-6 icon-box"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div className="icon">
            <i className="bi bi-globe"></i>
          </div>
          <h4 className="title">
            <a href="">Sed ut perspiciatis</a>
          </h4>
          <p className="description">
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur
          </p>
        </div>
        <div
          className="col-lg-4 col-md-6 icon-box"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div className="icon">
            <i className="bi bi-image"></i>
          </div>
          <h4 className="title">
            <a href="">Magni Dolores</a>
          </h4>
          <p className="description">
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
            officia deserunt mollit anim id est laborum
          </p>
        </div>
        <div
          className="col-lg-4 col-md-6 icon-box"
          data-aos="fade-up"
          data-aos-delay="400"
        >
          <div className="icon">
            <i className="bi bi-code-square pb-2"></i>
          </div>
          <h4 className="title">
            <a href="">Nemo Enim</a>
          </h4>
          <p className="description">
            At vero eos et accusamus et iusto odio dignissimos ducimus qui
            blanditiis praesentium voluptatum deleniti atque
          </p>
        </div>
        <div
          className="col-lg-4 col-md-6 icon-box"
          data-aos="fade-up"
          data-aos-delay="500"
        >
          <div className="icon">
            <i className="bi bi-calendar3"></i>
          </div>
          <h4 className="title">
            <a href="">Eiusmod Tempor</a>
          </h4>
          <p className="description">
            Et harum quidem rerum facilis est et expedita distinctio. Nam libero
            tempore, cum soluta nobis est eligendi
          </p>
        </div>
      </div>
    </div>
  );
}

export default Servicios;
