import React from "react";
import icon from "../assets/img/icon_f_white.png";
// <nav className='navbar-nav container justify-content-md-between text-center'>

// <li className="nav-item">
// <a className="nav-link mx-2" href="#team">
//   Nuestro equipo
// </a>
// </li>

const Navbar = () => {
  return (
    <>
      <nav className="navbar container navbar-expand-lg navbar-light bg-light sticky-top">
        <a className="navbar-brand" href="#">
          <h2 className="text-center mb-0">
            <img src={icon} className="navbar-icon pl-1 pb-1" alt="..." />
            AMAZONIA
            <span className="text-primary"> IC</span>
          </h2>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarTogglerDemo02"
        >
          <ul className="navbar-nav mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link active me-2" aria-current="page" href="#">
                Inicio
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link mx-2" href="#services">
                Servicios
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link mx-2" href="#aboutus">
                Quienes somos
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link mx-2" href="#portfolio">
                Portafolio
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link ms-2" href="#contact">
                Contacto
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
