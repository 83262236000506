import React from "react";

function Contact() {
  return (
    <div id="contact" className="container contenido">
      <div>
        <h2 className="text-center mb-5 text-dark">Contacto</h2>
      </div>

      <div className="row">
        <div className="col-lg-6 d-flex align-items-stretch" data-aos="fade-up">
          <div className="info-box">
            <div className="icon">
              <i className="bi bi-geo-alt"></i>
            </div>
            <h3>Nuestra dirección</h3>
            <p>A108 Adam Street, New York, NY 535022</p>
          </div>
        </div>

        <div
          className="col-lg-3 d-flex align-items-stretch"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="info-box">
            <div className="icon">
              <i className="bi bi-envelope"></i>
            </div>
            <h3>Correo</h3>
            <p>
              info@example.com
              <br />
              contact@example.com
            </p>
          </div>
        </div>

        <div
          className="col-lg-3 d-flex align-items-stretch"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div className="info-box ">
            <div className="icon">
              <i className="bi bi-telephone"></i>
            </div>
            <h3>Teléfonos</h3>
            <p>
              +1 5589 55488 55
              <br />
              +1 6678 254445 41
            </p>
          </div>
        </div>

        <div
          className="form-box col-lg-12"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <form
            action="forms/contact.php"
            method="post"
            role="form"
            className="php-email-form"
          >
            <div className="mb-3">
              <label for="name" className="form-label">
                Nombre
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Ingrese el nombre"
              />
            </div>
            <div className="mb-3">
              <label for="email" className="form-label">
                Correo
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="name@example.com"
              />
            </div>
            <div className="mb-3">
              <label for="subject" className="form-label">
                Asunto
              </label>
              <input
                type="text"
                className="form-control"
                id="subject"
                placeholder="Ingrese el asunto"
              />
            </div>
            <div className="mb-3">
              <label for="message" className="form-label">
                Mensaje
              </label>
              <textarea
                className="form-control"
                id="message"
                rows="3"
              ></textarea>
            </div>
            <div className="text-center">
              <a className="btn btn-secondary text-white" type="submit">
                Enviar
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
