import React from "react";
import card1 from "../assets/img/portafolio1.jpg";
import card2 from "../assets/img/portafolio2.jpg";
import card3 from "../assets/img/portafolio3.jpg";

// <div className='row mt-3'>
//     <div className='col-md-4'>
//         <div className='card'>
//             <img src={card1} className="card-img-top imag" alt="..." />
//             <div className='card-body text-center bg-primary text-white p-5'>
//                 <p className='display-5'>Producto 1</p>
//                 <p>Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC</p>
//             </div>
//         </div>
//     </div>
//     <div className='col-md-4'>
//         <div className='card'>
//             <img src={card2} className="card-img-top imag" alt="..." />
//             <div className='card-body text-center bg-primary text-white p-5'>
//                 <p className='display-5'>Producto 2</p>
//                 <p>Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC</p>
//             </div>
//         </div>
//     </div>
//     <div className='col-md-4'>
//         <div className='card'>
//             <img src={card3} className="card-img-top imag" alt="..." />
//             <div className='card-body text-center bg-primary text-white p-5'>
//                 <p className='display-5'>Producto 3</p>
//                 <p>Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC</p>
//             </div>
//         </div>
//     </div>
// </div>

function Portfolio() {
  return (
    <div className="contenido" id="portfolio">
      <h2 className="text-center text-dark">Portafolio</h2>
      Contrary to popular belief, Lorem Ipsum is not simply random text. It has
      roots in a piece of classical Latin literature from 45 BC, making it over
      2000 years old.
      <div className="card-group mt-3">
        <div className="card card-port">
          <img src={card1} className="card-img-top imag" alt="..." />
          <div className="card-body">
            <h5 className="card-title">Card title</h5>
            <p className="card-text">
              This is a wider card with supporting text below as a natural
              lead-in to additional content. This content is a little bit
              longer.
            </p>
            <p className="card-text">
              <small className="text-muted">Last updated 3 mins ago</small>
            </p>
          </div>
        </div>
        <div className="card card-port">
          <img src={card2} className="card-img-top imag" alt="..." />
          <div className="card-body">
            <h5 className="card-title">Card title</h5>
            <p className="card-text">
              This card has supporting text below as a natural lead-in to
              additional content.
            </p>
            <p className="card-text">
              <small className="text-muted">Last updated 3 mins ago</small>
            </p>
          </div>
        </div>
        <div className="card card-port h-160">
          <img src={card3} className="card-img-top imag" alt="..." />
          <div className="card-body">
            <h5 className="card-title">Card title</h5>
            <p className="card-text">
              This is a wider card with supporting text below as a natural
              lead-in to additional content. This card has even longer content
              than the first to show that equal height action.
            </p>
            <p className="card-text">
              <small className="text-muted">Last updated 3 mins ago</small>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
